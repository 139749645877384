<template>
  <div v-frag>
    <page-title title="request.requestDetails"></page-title>
    <!-- GARANCIÁLIS IGÉNY RÉSZLETEI -->
    <template v-if="serviceRequest.serviceRequest && serviceRequest.serviceRequest.warrantyType.id === 1 && !loadingRequestData">
      <request-details-garancia :service-request="serviceRequest"
                                v-on:expert-accept="quoteAcceptance(true, $event)"
                                v-on:expert-reject="quoteRejection(true, $event)"
                                v-on:open-loading-dialog="openLoadingDialog('A fájl letöltése folyamatban. Kérjük várjon.')"
                                v-on:close-loading-dialog="setupResponseDialog($event)">
      </request-details-garancia>
    </template>
    <!-- FIZETŐS IGÉNY RÉSZLETEI -->
    <template v-if="serviceRequest.serviceRequest && serviceRequest.serviceRequest.warrantyType.id === 2 && !loadingRequestData">
      <request-details-fizetos :service-request="serviceRequest"
                               v-on:quote-accept="quoteAcceptance"
                               v-on:quote-reject="quoteRejection"
                               v-on:open-loading-dialog="openLoadingDialog('A fájl letöltése folyamatban. Kérjük várjon.')"
                               v-on:close-loading-dialog="setupResponseDialog($event)">
      </request-details-fizetos>
    </template>
    <v-dialog v-model="loadingDialog.visible" persistent max-width="640">
      <v-card>
        <v-card-text class="pa-6">
          <v-row justify="center">
            <v-col cols="12" class="text-center mb-6">
              <v-progress-circular :size="70" :width="8" color="primary" indeterminate></v-progress-circular>
            </v-col>
            <v-col cols="12" class="text-center mb-6">
              <h1 class="font-weight-regular">
                {{ loadingDialog.title }}
              </h1>
            </v-col>
            <v-col cols="12" class="text-center">
              <span class="caption">
                Kérjük, ne zárja be a böngésző ablakot!
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" persistent max-width="640">
      <v-card>
        <v-card-text class="pa-6">
          <v-row justify="center">
            <v-col cols="12" class="text-center mb-6">
              <v-icon size="72" color="primary">
                {{ responseDialog.icon }}
              </v-icon>
            </v-col>
            <v-col cols="12" class="text-center mb-6">
              <h1 class="font-weight-regular">
                {{ responseDialog.title }}
              </h1>
            </v-col>
            <v-col cols="12">
              <div v-html="responseDialog.message"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeResponseDialog">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PageTitle from "../../components/titles/PageTitle.vue";
import RequestDetailsGarancia from "../../components/orders/RequestDetailsGarancia";
import RequestDetailsFizetos from "../../components/orders/RequestDetailsFizetos.vue";
import dayjs from 'dayjs';
import { getToken } from '../../utils/jwtHelper';

export default {
  name: 'InnerRequestDetailsPage',
  components: {
    PageTitle, RequestDetailsGarancia, RequestDetailsFizetos,
  },
  async beforeMount() {
    this.serviceRequest = await this.getRequestById(this.$route.params.crmRequestId);
    if (this.serviceRequest !== {}) {
      this.serviceRequest.history = await this.getRequestHistoryById(this.$route.params.crmRequestId);
      this.serviceRequest.documents = await this.getRequestDocumentsById(this.$route.params.crmRequestId);
      // jegyzőkönyv keresés a gsm proxy szervizekben
      if (this.serviceRequest.serviceRequest.gsmWorksheetId) {
        this.serviceRequest.gsmWorksheetNumber = this.serviceRequest.serviceRequest.gsmWorksheetId;
        this.serviceRequest.returnWorksheetDocumentId = await this.getReturnWorksheetDocumentId(this.serviceRequest.serviceRequest.gsmWorksheetId);
        if (this.serviceRequest.returnWorksheetDocumentId === 0) {
          if (this.serviceRequest.serviceRequest.gsmWorksheetIdOrig) {
            this.serviceRequest.gsmWorksheetNumber = this.serviceRequest.serviceRequest.gsmWorksheetIdOrig;
            this.serviceRequest.returnWorksheetDocumentId = await this.getReturnWorksheetDocumentId(this.serviceRequest.serviceRequest.gsmWorksheetIdOrig);
          }
        }
      } else if (this.serviceRequest.serviceRequest.gsmWorksheetIdOrig) {
        this.serviceRequest.gsmWorksheetNumber = this.serviceRequest.serviceRequest.gsmWorksheetIdOrig;
        this.serviceRequest.returnWorksheetDocumentId = await this.getReturnWorksheetDocumentId(this.serviceRequest.serviceRequest.gsmWorksheetIdOrig);
      }
      this.serviceRequest.returnReport = this.serviceRequest.returnWorksheetDocumentId > 0;
      await this.setHistory();
    } else {
      this.loginIsSuccess = false;
      this.loadingRequestData = false;
    }
    // console.log('InnerRequestDetailsPage - this.serviceRequest: ', this.serviceRequest);
  },
  props: {
    //
  },
  data() {
    return {
      serviceRequest: {},
      loadingDialog: {
        visible: false,
        title: '',
      },
      responseDialog: {
        visible: false,
        title: '',
        message: '',
        icon: '',
      },
      loadingRequestData: true,
      loginIsSuccess: false,
    };
  },
  methods: {
    async getRequestById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestById', crmRequestId);
      // console.log('getRequestById response: ', response);
      if (response.status === 200) {
        return response.data;
      }
    },
    async getRequestHistoryById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestHistoryById', crmRequestId);
      // console.log('getRequestHistoryById response: ', response);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    },
    async getRequestDocumentsById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestDocumentsById', crmRequestId);
      // console.log('getRequestDocumentsById response: ', response);
      if (response.status === 200) {
        return response.data;
      } else {
        return {};
      }
    },
    async setHistory() {
      // this.$store.dispatch('setRequestHistory', { warrantyType: this.serviceRequest.serviceRequest.warrantyType.id, history: this.serviceRequest.history})
      // console.log('START setRequestHistory');
      // let requestHistory = this.serviceRequest.history;
      let hasRejectedQuote = this.serviceRequest.history.findIndex(item => {
        return item.code === 34;
      });
      let hasNoAnswerQuote = this.serviceRequest.history.findIndex(item => {
        return item.code === 36;
      });
      let templateHistory = this.serviceRequest.serviceRequest.warrantyType.id === 1 ? this.$store.state.generalManagement.warrantyRequestStatusList : this.$store.state.generalManagement.payedRequestStatusList;
      for (let i = 0; i < templateHistory.length; i++) {
        let isContain = this.serviceRequest.history.findIndex( item => {
          return item.code === templateHistory[i].code;
        });
        if (templateHistory[i].code === 32) {
          if (hasRejectedQuote > 0) {
            //
          } else if (hasNoAnswerQuote > 0) {
            //
          } else if (isContain === -1) {
            this.serviceRequest.history.push(templateHistory[i]);
          }
        } else {
          if (isContain === -1) {
            this.serviceRequest.history.push(templateHistory[i]);
          }
        }
      }
      this.convertDateToDisplayDate();
      // console.table(this.serviceRequest.history);
      // const currentStatusIndex = this.findCurrentStatus();
      // console.log('currentStatus: ', this.serviceRequest.history[currentStatusIndex].code);
      // this.serviceRequest.currentStatus = this.serviceRequest.history[currentStatusIndex].code;
      this.serviceRequest.currentStatus = this.serviceRequest.serviceRequest.statusId;
      // console.log('END setRequestHistory');
      this.loadingRequestData = false;
    },
    findCurrentStatus() {
      this.serviceRequest.history.sort((a,b) => {
        return a.code - b.code;
      })
      this.serviceRequest.history.forEach((item,index) => {
        // console.log(`${index}. status: ${item.status}, code: ${item.code}, date: ${item.date}`);
      })
      for (let i = this.serviceRequest.history.length - 1; i >=0; i--) {
        if (this.serviceRequest.history[i].date !== null) {
          return i;
        }
      }
      return 0;
    },
    convertDateToDisplayDate() {
      for (let i = 0; i < this.serviceRequest.history.length; i++) {
        if (this.serviceRequest.history[i].date) {
          // Régebbi böngészők miatt a db-ből kapott dátumot a - jeleknél splittelni kell
          const dateWithoutTimeString = this.serviceRequest.history[i].date.substring(0, this.serviceRequest.history[i].date.lastIndexOf(' '));
          // console.log('dateWithoutTimeString: ', dateWithoutTimeString);
          this.serviceRequest.history[i].dateToDisplay = dateWithoutTimeString.replace(/-/g,'.');
          // console.log('dateToDisplay: ', this.serviceRequest.history[i].dateToDisplay);
        } else {
          this.serviceRequest.history[i].dateToDisplay = '';
        }
      }
    },
    async quoteAcceptance(isExpert = false, $event = null) {
      const document = isExpert ? 'A jegyzőkönyv' : 'Az ajánlat';
      let titleSuccess = `${document} elfogadása sikeres volt.`;
      let iconSuccess = 'mdi-emoticon-happy-outline';
      let titleError = `${document} elfogadása nem sikerült.`;
      let iconError = 'mdi-emoticon-sad-outline';
      // console.log(`${document} elfogadása...`);
      this.openLoadingDialog(`${document} elfogadása folyamatban...`);
      let response = null;
      if (isExpert) {
        response = await this.$store.dispatch('AcceptExpertReport',
          {
            requestId: this.serviceRequest.serviceRequest.id,
            accept: true,
            returnReport: $event ? $event.returnReport : false,
            token: getToken()
          }
        );
      } else {
        response = await this.$store.dispatch('AcceptQuote', { requestId: this.serviceRequest.serviceRequest.id, accept: true, token: getToken()});
      }
      if(response === 200) {
        this.responseDialog.title = titleSuccess;
        this.responseDialog.icon = iconSuccess;
      } else {
        this.responseDialog.title = titleError;
        this.responseDialog.icon = iconError;
        this.responseDialog.message = `<p class="text-center">${document} elfogadása nem sikerült. Kérjük próbálja meg újra, vagy vegye fel a kapcsolatot az ügyfélszolgálatunkkal.</p>`;
      }
      this.controlDialogs();
    },
    async quoteRejection(isExpert = false, $event = null) {
      const document = isExpert ? 'A jegyzőkönyv' : 'Az ajánlat';
      let titleSuccess = `${document} elutasítása sikeres volt.`;
      let titleError = `${document} elutasítása nem sikerült.`;
      let icon = 'mdi-emoticon-sad-outline';
      // console.log(`${document} elutasítása...`);
      this.openLoadingDialog(`${document} elutasítása folyamatban...`);
      let response = null;
      if (isExpert) {
        response = await this.$store.dispatch('AcceptExpertReport',
          {
            requestId: this.serviceRequest.serviceRequest.id,
            accept: false,
            returnReport: $event ? $event.returnReport : false,
            token: getToken()
          }
        );
      } else {
        response = await this.$store.dispatch('AcceptQuote', { requestId: this.serviceRequest.serviceRequest.id, accept: false, token: getToken()});
      }
      if(response === 200) {
        this.responseDialog.title = titleSuccess;
        this.responseDialog.icon = icon;
      } else {
        this.responseDialog.title = titleError;
        this.responseDialog.icon = icon;
        this.responseDialog.message = `<p class="text-center">${document} elutasítása nem sikerült. Kérjük próbálja meg újra, vagy vegye fel a kapcsolatot az ügyfélszolgálatunkkal.</p>`;
      }
      this.controlDialogs();
    },
    openLoadingDialog(message) {
      this.loadingDialog.title = message;
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      window.location.reload();
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3200);
      setTimeout(this.openResponseDialog, 3300);
    },
    apiCall(type) {
      const now = new Date();
      if (type === 1) {
        this.responseDialog.title = 'Az ajánlat elfogadása sikeres volt.';
        this.responseDialog.icon = 'mdi-emoticon-happy-outline';
      } else if (type === 2) {
        this.serviceRequest.history[3].status = 'QUOTE_REJECTED';
        this.serviceRequest.history[3].name = 'Elutasított árajánlat';
        this.responseDialog.title = 'Az ajánlat elutasítása megtörtént.';
        this.responseDialog.icon = 'mdi-emoticon-sad-outline';
      }
      this.serviceRequest.history[3].date = dayjs(now).format('YYYY. MM. DD H:mm');
      this.responseDialog.message = '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque iaculis tellus vel commodo ornare. Integer molestie commodo aliquet. Praesent aliquet sem sapien, id viverra turpis eleifend at. Sed ut dignissim nunc, at pretium mi.</p>';
      this.controlDialogs();
    },
    async callGetGSMWorksheetDetails(gsmWorksheetId) {
      return await this.$store.dispatch('gsmApi/GetGSMWorksheetDetails', gsmWorksheetId);
    },
    async getReturnWorksheetDocumentId(gsmWorksheetId) {
      const response = await this.callGetGSMWorksheetDetails(gsmWorksheetId);
      if (response.status === this.$HTTP_OK) {
        return response.data.gsm_worksheet_data.id_jegyzokonyv;
      }
      return 0;
    },
    setupResponseDialog($event) {
      if ($event.status === 200) {
        this.closeLoadingDialog();
      } else {
        this.responseDialog.title = $event.title;
        this.responseDialog.message = $event.message;
        this.controlDialogs();
      }
    }
  },
};
</script>

<style scoped>

</style>
